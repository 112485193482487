import  React, { useState, useEffect}  from "react";
import { graphql, navigate, Link } from "gatsby";
import Layout from "../components/layout";
import calcDate from "../components/date";
import axios from "axios";
import Seo from "../components/seo";
import Player from "../components/player";
import {useSwipeable} from "react-swipeable"

export default function Fav({data}) {
  const { Audio, Photo, Id } = data.currentFav.data;
  const prevPhoto = data.prevFav ? data.prevFav.data : null;
  const nextPhoto = data.nextFav ? data.nextFav.data : null;
  const recordId = data.currentFav.recordId;
  const date = calcDate(Id);

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)


  const handlers = useSwipeable({
    onSwiped: (eventData) => console.log("User Swiped!", eventData),
    onSwipedRight: () => {if (prevPhoto) navigate(`/fav/${prevPhoto.Id}`)},
    onSwipedLeft: () => {if (nextPhoto) navigate(`/fav/${nextPhoto.Id}`)},
    preventDefaultTouchmoveEvent: false,
    trackMouse: true
  });


  useEffect(() => {
    document.onkeydown = function (e) {
      switch (e.key) {
        case "ArrowLeft":
          if (prevPhoto) navigate(`/fav/${prevPhoto.Id}`);
          break;
        case "ArrowRight":
          if (nextPhoto) navigate(`/fav/${nextPhoto.Id}`);
      }
    };
  }, []);

  const tellMe = async () => {
    setIsSubmitting(true)
    axios
      .post("https://opowiedz.maciejkorsan.workers.dev/", {
        id: recordId,
      })
      .then((res) => {
        setIsSubmitting(false)
        setIsSubmitted(true)
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <Layout>
      <Seo title={`Zdjęcie nr ${Id}`} />
      <main className="grid">
        <div className="photo"  {...handlers}>
          <div className="photo__background">
            <img
              src={Photo}
              alt={`Zdjęcie wykonane ${date}`}
              className="photo__cover"
            />
          </div>
          <div className="photo__main">
            <img src={Photo} alt={`Zdjęcie wykonane ${date}`} />
          </div>
          <h1 className="photo__title">
            #{Id} ({date})
          </h1>
        </div>

        <div className="info">
          <div className="photo__nav">
            {prevPhoto && (
              <Link to={`/fav/${prevPhoto.Id}`}>&larr; #{prevPhoto.Id}</Link>
            )}
          </div>
          <div className="player-desktop">
            {Audio ? (
              <Player file={Audio}></Player>
            ) : (
              <button disabled={isSubmitted} className={`button button--submit ${isSubmitting ? 'button--loading' : ''}`} onClick={tellMe}>
                {!isSubmitted ? 'Opowiedz mi Korsi!' : 'Opowiem wkrótce!'}
              </button>
            )}
          </div>
          <div className="photo__nav">
            {nextPhoto && (
              <Link to={`/fav/${nextPhoto.Id}`}>#{nextPhoto.Id} &rarr;</Link>
            )}
          </div>
        </div>

        <div className="player-mobile">
            {Audio ? (
              <Player file={Audio}></Player>
            ) : (
              <button disabled={isSubmitted} className={`button button--submit ${isSubmitting ? 'button--loading' : ''}`} onClick={tellMe}>
                {!isSubmitted ? 'Opowiedz mi Korsi!' : 'Opowiem wkrótce!'}
              </button>
            )}
          </div>
      </main>
      <div className="home">
      <Link to="/">Wróć na stronę główną</Link>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($id: Int!, $nextFav: Int, $prevFav: Int) {
    currentFav: airtable(data: { Id: { eq: $id } }) {
      data {
        Audio
        Photo
        Id
      }
      recordId
    }

    nextFav: airtable(data: { Id: { eq: $nextFav } }) {
      data {
        Audio
        Photo
        Id
      }
      recordId
    }

    prevFav: airtable(data: { Id: { eq: $prevFav } }) {
      data {
        Audio
        Photo
        Id
      }
      recordId
    }
  }
`;
